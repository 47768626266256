import React from "react";

export default function Impressum() {
  return (
    <>
      <h2>IMPRESSUM</h2>
      <h4>Amanda Martikainen</h4>
      <h4>
        <a href="mailto:amandamartikainen@protonmail.com">
          amandamartikainen@protonmail.com
        </a>
      </h4>
      <h4>
        Website:&nbsp;
        <a
          href="https://timchandler.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tim Chandler
        </a>
      </h4>
      <h4>&#169; 2020–2024 Amanda Martikainen</h4>
    </>
  );
}
