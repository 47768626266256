import React, { useContext } from 'react';
import { LanguageContext } from '../language';
import { ReactComponent as CloseIcon } from '../svg/close.svg';

export default function CloseButton({ onModalClose }) {
  const { dictionary } = useContext(LanguageContext);

  return (
    <button
      onClick={onModalClose}
      className='closer-button'
      aria-label={dictionary.close}>
      <CloseIcon className='component-closer' title={dictionary.close} />
    </button>
  );
}
