import { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../language';

export default function useDates() {
  const { userLanguage } = useContext(LanguageContext);
  const runtimeLocale = new Intl.NumberFormat().resolvedOptions().locale;
  const [locale, setLocale] = useState(runtimeLocale);

  useEffect(() => {
    if (runtimeLocale.includes(userLanguage)) {
      setLocale(runtimeLocale);
    } else if (userLanguage === 'en') {
      setLocale('en-GB');
    } else {
      setLocale('de');
    }
  }, [userLanguage, runtimeLocale]);

  const formatDate = date => {
    return date.toLocaleDateString(locale);
  };

  const sortByDate = ({ events = [], desc = false }) => {
    if (desc) {
      return events.sort((a, b) => b.date.getTime() - a.date.getTime());
    } else {
      return events.sort((a, b) => a.date.getTime() - b.date.getTime());
    }
  };

  return [sortByDate, formatDate];
}
