import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Text } from '../../language';
import { updateComponent } from '../../state/actions';

export default function Error() {
  const dispatch = useDispatch();
  const history = useHistory();

  const closeComponent = () => {
    history.push('/');
    dispatch(updateComponent('home'));
  };

  return (
    <div className='center'>
      <h3 className='italic bold'>
        <Text tid='oops' />
      </h3>
      <h4>
        <Text tid='error' />
      </h4>
      <button className='ok button' onClick={closeComponent} type='button'>
        OK
      </button>
    </div>
  );
}
