import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { LanguageContext } from '../language';
import { updateComponent } from '../state/actions';
import Menu from './menu';
import { ReactComponent as BurgerIcon } from '../svg/burger.svg';

export default function Header() {
  const dispatch = useDispatch();
  const [menuState, setMenuState] = useState('closed');

  const { dictionary } = useContext(LanguageContext);
  const history = useHistory();

  const closeComponent = () => {
    history.push('/');
    dispatch(updateComponent('home'));
  };

  const handleClick = () => {
    if (menuState === 'closed') {
      setMenuState('open');
    } else {
      setMenuState('closed');
    }
  };

  return (
    <header>
      <h1 onClick={closeComponent}>Amanda Martikainen</h1>
      <Menu menuState={menuState} />
      <button
        tabIndex={1}
        onClick={handleClick}
        className='menu-button'
        aria-label={dictionary.menu}>
        <BurgerIcon title={dictionary.menu} className={`burger ${menuState}`} />
      </button>
    </header>
  );
}
