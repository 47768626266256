import React from 'react';
import { useSelector } from 'react-redux';

import Video from '../video';

export default function Videos() {
  const videos = useSelector(state => state.media?.videos) || [];

  return (
    <div className='video-container'>
      {videos.map((item, index) => (
        <Video key={index} {...item.fields} />
      ))}
    </div>
  );
}
