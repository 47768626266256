import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { LanguageContext } from '../../language';

export default function Contact() {
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory();
  const [formData, setFormData] = useState({});

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formData,
      }),
    })
      .then(() => history.push('/thanks'))
      .catch(err => {
        history.push('/error');
        console.log(err);
      });
  };

  return (
    <form
      name='contact'
      method='POST'
      action='/thanks'
      onSubmit={submitForm}
      data-netlify='true'
      data-netlify-honeypot='bot-field'>
      <input type='hidden' name='form-name' value='contact' />
      <p className='hidden'>
        <label htmlFor='bot-field'>Don't fill this out if you're human:</label>
        <input name='bot-field' id='bot-field' />
      </p>
      <p>
        <label htmlFor='name'>{`${dictionary.name}*`}</label>
        <br />
        <input
          type='text'
          name='name'
          id='name'
          placeholder={`${dictionary.yrname}`}
          onChange={handleChange}
          required
        />
      </p>
      <p>
        <label htmlFor='email'>{`${dictionary.email}*`}</label>
        <br />
        <input
          type='email'
          name='email'
          id='email'
          placeholder={`${dictionary.yremail}`}
          onChange={handleChange}
          required
        />
      </p>
      <p>
        <label htmlFor='subject'>{`${dictionary.subject}*`}</label>
        <br />
        <input
          type='text'
          name='subject'
          id='subject'
          placeholder={`${dictionary.subject}`}
          maxLength='50'
          onChange={handleChange}
          required
        />
      </p>
      <p>
        <label htmlFor='message'>{`${dictionary.message}*`}</label>
        <br />
        <textarea
          name='message'
          id='message'
          onChange={handleChange}
          required></textarea>
      </p>
      <p className='center'>{`${dictionary.requirement}`}</p>
      <p className='center'>
        <button className='button' type='submit'>
          {dictionary.submit}
        </button>
      </p>
    </form>
  );
}
