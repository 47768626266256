import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { LanguageContext } from "../../language";

export default function About() {
  const { dictionary } = useContext(LanguageContext);

  const bio = useSelector((state) => state.bio) || {
    en: { content: [] },
    de: { content: [] },
  };

  return (
    <article className="about-text">
      {dictionary.language === "EN" &&
        bio.en.content.map((para, index) => {
          return <p key={index}>{para.content[0].value}</p>;
        })}
      {dictionary.language === "DE" &&
        bio.de.content.map((para, index) => {
          return <p key={index}>{para.content[0].value}</p>;
        })}
    </article>
  );
}
