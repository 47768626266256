const contentful = require('contentful');
// const secrets = require('../secrets.json');

// const client = contentful.createClient({
//   accessToken: secrets.REACT_APP_API_TOKEN,
//   space: secrets.REACT_APP_API_SPACE_ID,
// });

const client = contentful.createClient({
  accessToken: process.env.REACT_APP_API_TOKEN,
  space: process.env.REACT_APP_API_SPACE_ID,
});

const standardizeEvents = events => {
  return events.map(e => ({
    ...e.fields,
    date: new Date(e.fields.date),
  }));
};

export async function getBio() {
  try {
    const data = await client.getEntries({
      content_type: 'about',
    });
    return {
      type: 'GET_BIO',
      bio: data.items[0].fields,
    };
  } catch (err) {
    console.log(err);
  }
}

export async function getEvents() {
  try {
    const data = await client.getEntries({
      content_type: 'events',
    });
    return {
      type: 'GET_EVENTS',
      events: standardizeEvents(data.items),
    };
  } catch (err) {
    console.log(err);
  }
}

export async function getMedia() {
  try {
    const videos = await client.getEntries({
      content_type: 'videos',
    });
    const links = await client.getEntries({
      content_type: 'links',
    });
    return {
      type: 'GET_MEDIA',
      media: { videos: videos.items, links: links.items },
    };
  } catch (err) {
    console.log(err);
  }
}

export function updateComponent(component) {
  return {
    type: 'UPDATE_COMPONENT',
    component,
  };
}

export function updateTab(tab) {
  return {
    type: 'UPDATE_TAB',
    tab,
  };
}
