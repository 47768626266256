import React from 'react';

import { ReactComponent as Instagram } from '../svg/instagram.svg';
import { ReactComponent as Soundcloud } from '../svg/soundcloud.svg';
import { ReactComponent as Youtube } from '../svg/youtube.svg';
import { ReactComponent as Spotify } from '../svg/spotify.svg';
import { ReactComponent as Vimeo } from '../svg/vimeo.svg';

export default function Links() {
  const clickHandler = e => {
    if (e.type === 'mouseup') {
      e.currentTarget.blur();
    }
  };

  return (
    <div className='links'>
      <div className='icon-container instagram'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.instagram.com/amandaidsounds/'
          aria-label='Instagram'
          onClick={clickHandler}>
          <Instagram className='icon' />
        </a>
      </div>
      <div className='icon-container soundcloud'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://soundcloud.com/martikainenamanda'
          aria-label='Soundcloud'
          onClick={clickHandler}>
          <Soundcloud className='icon' />
        </a>
      </div>
      <div className='icon-container youtube'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.youtube.com/channel/UCenSHAwYzMXPMVDWg0LyF7A'
          aria-label='YouTube'
          onClick={clickHandler}>
          <Youtube className='icon' />
        </a>
      </div>
      <div className='icon-container spotify'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://open.spotify.com/artist/24BSpToOiAD84uIbrpUmqF'
          aria-label='Spotify'
          onClick={clickHandler}>
          <Spotify className='icon' />
        </a>
      </div>
      <div className='icon-container vimeo'>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://vimeo.com/user144545635'
          aria-label='Vimeo'
          onClick={clickHandler}>
          <Vimeo className='icon' />
        </a>
      </div>
    </div>
  );
}
