import React from 'react';
import { Route, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateTab } from '../../state/actions';
import Videos from './media-videos';
import Links from './media-links';

export default function Media() {
  const dispatch = useDispatch();
  const currentTab = useSelector(state => state.currentTab);

  return (
    <div>
      <nav className='media-nav'>
        <Link
          to='/media/videos'
          className={`tab-nav-item${currentTab === 'VIDEOS' ? ' current' : ''}`}
          onClick={() => dispatch(updateTab('VIDEOS'))}>
          VIDEOS
        </Link>
        <Link
          to='/media/links'
          className={`tab-nav-item${currentTab === 'LINKS' ? ' current' : ''}`}
          onClick={() => dispatch(updateTab('LINKS'))}>
          LINKS
        </Link>
      </nav>
      <Route path='/media/videos' render={() => <Videos />} />
      <Route path='/media/links' render={() => <Links />} />
    </div>
  );
}
