import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { LanguageContext } from '../language';

export default function Head() {
  const { userLanguage, dictionary } = useContext(LanguageContext);

  return (
    <Helmet htmlAttributes={{ lang: userLanguage }}>
      <meta name='description' content={dictionary.content} />

      <meta property='og:title' content='Amanda Martikainen' />
      <meta property='og:type' content='website' />
      <meta property='og:description' content={dictionary.content} />
      <meta property='og:url' content='https://iamamandamartikainen.com/' />
      <meta
        property='og:image'
        content='https://amanda.s3.eu-west-1.amazonaws.com/main-1280.jpg'
      />
      <meta property='og:site_name' content='Amanda Martikainen' />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content='Amanda Martikainen' />
      <meta name='twitter:description' content={dictionary.content} />
      <meta
        name='twitter:image'
        content='https://amanda.s3.eu-west-1.amazonaws.com/main-671.jpg'
      />
    </Helmet>
  );
}
