import React, { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Div100vh from 'react-div-100vh';

import { useDispatch } from 'react-redux';

import {
  getBio,
  getEvents,
  getMedia,
  updateComponent,
  updateTab,
} from './state/actions';
import { LanguageProvider } from './language';
import './App.css';

import Head from './components/helmet';
import Header from './components/header';
import Links from './components/links';
import Modal from './components/modal';
import About from './components/routes/about';
import Contact from './components/routes/contact';
import Events from './components/routes/events';
import Media from './components/routes/media';
import Impressum from './components/routes/impressum';
import Success from './components/routes/success';
import Error from './components/routes/error';

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBio());
    dispatch(getEvents());
    dispatch(getMedia());
    if (window.location.pathname === '/') {
      dispatch(updateComponent('home'));
    } else if (window.location.pathname === '/about') {
      dispatch(updateComponent('about'));
    } else if (
      window.location.pathname === '/contact' ||
      window.location.pathname === '/thanks'
    ) {
      dispatch(updateComponent('contact'));
    } else if (window.location.pathname === '/events') {
      dispatch(updateComponent('events'));
    } else if (window.location.pathname === '/media/videos') {
      dispatch(updateComponent('media'));
      dispatch(updateTab('VIDEOS'));
    } else if (window.location.pathname === '/media/links') {
      dispatch(updateComponent('media'));
      dispatch(updateTab('LINKS'));
    } else if (window.location.pathname === '/impressum') {
      dispatch(updateComponent('impressum'));
    }
  }, [dispatch]);

  return (
    <LanguageProvider>
      <Div100vh>
        <div className='App'>
          <Head />
          <div className='home'>
            <img
              srcSet='https://amanda.s3.eu-west-1.amazonaws.com/main-671.jpg 671w, 
                      https://amanda.s3.eu-west-1.amazonaws.com/main-954.jpg 954w,
                      https://amanda.s3.eu-west-1.amazonaws.com/main-1280.jpg 1280w, 
                      https://amanda.s3.eu-west-1.amazonaws.com/main-1440.jpg 1440w,
                      https://amanda.s3.eu-west-1.amazonaws.com/main-1920.jpg 1920w,
                      https://amanda.s3.eu-west-1.amazonaws.com/main-2880.jpg 2880w,'
              sizes='100vw'
              src='https://amanda.s3.eu-west-1.amazonaws.com/main-1280.jpg'
              alt='Amanda Martikainen'
            />
          </div>

          <BrowserRouter>
            <Header />
            <Links />
            <Route
              path='/about'
              render={() => <Modal variant='about' children={<About />} />}
            />
            <Route
              path='/contact'
              render={() => <Modal variant='contact' children={<Contact />} />}
            />
            <Route
              path='/events'
              render={() => <Modal variant='events' children={<Events />} />}
            />
            <Route
              path='/media'
              render={() => <Modal variant='media' children={<Media />} />}
            />
            <Route
              path='/impressum'
              render={() => (
                <Modal variant='impressum' children={<Impressum />} />
              )}
            />
            <Route
              path='/thanks'
              render={() => <Modal variant='contact' children={<Success />} />}
            />
            <Route
              path='/error'
              render={() => <Modal variant='contact' children={<Error />} />}
            />
          </BrowserRouter>
        </div>
      </Div100vh>
    </LanguageProvider>
  );
}
