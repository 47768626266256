import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { LanguageContext, Text } from "../../language";
import useDates from "../../hooks/useDates";

export default function Events() {
  const [sortByDate, formatDate] = useDates();
  const { dictionary } = useContext(LanguageContext);

  const events = useSelector((state) => state.events) || [];
  const hasEvents = events.length > 0;
  const sortedEvents = hasEvents ? sortByDate({ events }) : [];

  return (
    <>
      <h2 className="allcaps">
        <Text tid="upcoming" />
      </h2>
      <section className="eventlist">
        {sortedEvents.map((event, index) => {
          return (
            <div className="event-container" key={index}>
              <h3>
                {formatDate(event.date)} &#x2F;&#x2F; {event.location}
              </h3>
              {dictionary.language === "EN" && <h4>{event.descriptionEN}</h4>}
              {dictionary.language === "DE" && <h4>{event.descriptionDE}</h4>}
            </div>
          );
        })}
        {!hasEvents && (
          <h3 className="italic">
            <Text tid="noevents" />
          </h3>
        )}
      </section>
    </>
  );
}
