import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateComponent, updateTab } from '../state/actions';
import LanguageSelector from './language-selector';
import { Text } from '../language';

export default function Menu({ menuState }) {
  const dispatch = useDispatch();
  const currentComponent = useSelector(state => state.currentComponent);

  return (
    <div className='menu-container'>
      <div className={`menu ${menuState}`}>
        <nav>
          <Link
            to='/'
            className={`nav-item${
              currentComponent === 'home' ? ' current' : ''
            }`}
            onClick={() => dispatch(updateComponent('home'))}
            onMouseUp={e => e.currentTarget.blur()}>
            <Text tid='home' />
          </Link>
          <Link
            to='/about'
            className={`nav-item${
              currentComponent === 'about' ? ' current' : ''
            }`}
            onClick={() => dispatch(updateComponent('about'))}
            onMouseUp={e => e.currentTarget.blur()}>
            <Text tid='about' />
          </Link>
          <Link
            to='/events'
            className={`nav-item${
              currentComponent === 'events' ? ' current' : ''
            }`}
            onClick={() => dispatch(updateComponent('events'))}
            onMouseUp={e => e.currentTarget.blur()}>
            <Text tid='events' />
          </Link>
          <Link
            to='/media/videos'
            className={`nav-item${
              currentComponent === 'media' ? ' current' : ''
            }`}
            onClick={() => {
              dispatch(updateComponent('media'));
              dispatch(updateTab('VIDEOS'));
            }}
            onMouseUp={e => e.currentTarget.blur()}>
            <Text tid='media' />
          </Link>
          <Link
            to='/contact'
            className={`nav-item${
              currentComponent === 'contact' ? ' current' : ''
            }`}
            onClick={() => dispatch(updateComponent('contact'))}
            onMouseUp={e => e.currentTarget.blur()}>
            <Text tid='contact' />
          </Link>
          <Link
            to='/impressum'
            className={`nav-item${
              currentComponent === 'impressum' ? ' current' : ''
            }`}
            onClick={() => dispatch(updateComponent('impressum'))}
            onMouseUp={e => e.currentTarget.blur()}>
            Impressum
          </Link>
          <LanguageSelector />
        </nav>
      </div>
    </div>
  );
}
