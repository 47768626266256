import React from 'react';

export default function Video({ url, title = 'Video' }) {
  return url ? (
    <div className='iframe-container'>
      <iframe
        src={url}
        width='640'
        height='360'
        frameBorder='0'
        title={title}
        allow="fullscreen; picture-in-picture; clipboard-write; camera 'none'; microphone 'none'; sync-xhr 'none'"
        referrerPolicy='no-referrer'
        loading='lazy'
        allowFullScreen></iframe>
    </div>
  ) : null;
}
