export default function reducer(state = {}, action) {
  if (action.type === 'UPDATE_COMPONENT') {
    return (state = { ...state, currentComponent: action.component });
  }

  if (action.type === 'UPDATE_TAB') {
    return (state = { ...state, currentTab: action.tab });
  }

  if (action.type === 'GET_BIO') {
    return (state = { ...state, bio: action.bio });
  }

  if (action.type === 'GET_EVENTS') {
    return (state = { ...state, events: action.events });
  }

  if (action.type === 'GET_MEDIA') {
    return (state = { ...state, media: action.media });
  }

  return state;
}
