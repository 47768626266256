import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { LanguageContext } from '../../language';

export default function Links() {
  const links = useSelector(state => state.media?.links) || [];
  const { dictionary } = useContext(LanguageContext);

  return (
    <div className='links-container'>
      {links.map((item, index) => {
        const language = dictionary.language === 'DE' ? 'titleDE' : 'titleEN';
        const url = item?.fields?.url;
        return (
          <p key={index}>
            <a target='_blank' rel='noopener noreferrer' href={url}>
              {item?.fields &&
                item?.fields[language]?.content[0]?.content[0]?.value}
            </a>
          </p>
        );
      })}
    </div>
  );
}
