import React, { useEffect, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { use100vh } from 'react-div-100vh';
import CloseButton from './close-button';
import { updateComponent } from '../state/actions';

/* 
Accessible modal dialog based on: 
https://tinloof.com/blog/how-to-create-an-accessible-react-modal/
*/

export default function Modal({ variant, children }) {
  const modalRef = createRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const height = use100vh();

  const closeModal = () => {
    history.push('/');
    dispatch(updateComponent('home'));
  };

  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }
    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  });

  const handleTabKey = e => {
    const focusableModalElements = modalRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="email"]'
    );

    const firstElement = focusableModalElements[0];
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1];
    const index = Array.prototype.indexOf.call(
      focusableModalElements,
      document.activeElement
    );

    if (!e.shiftKey) {
      if (index === -1 || index === focusableModalElements.length - 1) {
        firstElement.focus();
        return e.preventDefault();
      } else {
        focusableModalElements[index + 1].focus();
        return e.preventDefault();
      }
    }

    if (e.shiftKey) {
      if (index === -1 || index === 0) {
        lastElement.focus();
        return e.preventDefault();
      } else {
        focusableModalElements[index - 1].focus();
        return e.preventDefault();
      }
    }
  };

  const keyListenersMap = new Map([
    [27, closeModal],
    [9, handleTabKey],
  ]);

  return createPortal(
    <div style={{ height: height }} className='modal-container'>
      <div
        className={`modal ${variant}`}
        role='dialog'
        aria-modal='true'
        ref={modalRef}>
        <CloseButton onModalClose={closeModal} />
        {children}
      </div>
    </div>,
    document.body
  );
}
