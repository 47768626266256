import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../language';

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  const handleLanguageChange = e => {
    userLanguageChange(e.target.id);
    e.currentTarget.blur();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      userLanguageChange(e.target.id);
    } else {
      return;
    }
  };

  useEffect(() => {
    let defaultLanguage = window.localStorage.getItem('rcml-lang');
    if (!defaultLanguage) {
      defaultLanguage = window.navigator.language.substring(0, 2);
    }
    userLanguageChange(defaultLanguage);
  }, [userLanguageChange]);

  return (
    <span className='nav-item'>
      <span
        tabIndex='0'
        id='en'
        className={`${
          userLanguage === 'en' ? 'current-language' : 'other-language'
        }`}
        onClick={handleLanguageChange}
        onKeyDown={handleKeyDown}>
        EN
      </span>
      <span className='other-language'>&nbsp;/&nbsp;</span>
      <span
        tabIndex='0'
        id='de'
        className={`${
          userLanguage === 'de' ? 'current-language' : 'other-language'
        }`}
        onClick={handleLanguageChange}
        onKeyDown={handleKeyDown}>
        DE
      </span>
    </span>
  );
}
